import React, { useState } from 'react'
import styled from 'styled-components'
import { Tooltip } from 'react-tooltip'
import { Link } from 'gatsby'

import LogoCobj from '../../images/cobj.gif'
import LogoPBJ from '../../images/pbj.gif'
import LogoVOBJ from '../../images/vobj.gif'

import Facebook from '../../images/facebook.svg'
import Twitter from '../../images/twitter.svg'
import Instagram from '../../images/instagram.svg'
import Youtube from '../../images/youtube.svg'

import FacebookPink from '../../images/facebook_pink.svg'
import TwitterPink from '../../images/twitter_pink.svg'
import InstagramPink from '../../images/instagram_pink.svg'
import YoutubePink from '../../images/youtube_pink.svg'

const Container = styled.footer`
  max-width: calc(1400px - 4rem);
  margin-left: auto;
  margin-right: auto;
  display: flex;
  min-height: 130px;
  justify-content: space-around;
  align-items: center;
  border-top: 1px solid #EBEAEA;
  column-gap: 17px;
  @media (max-width: 880px) {
    flex-direction: column;
    padding-top: 2rem;
    padding-bottom: 1rem;
    max-width: calc(100vw - 1rem);
    min-height: 260px;
  }
  a {
    font-family: Catamaran, sans-serif;
    color: #000;
  }
  a.pbj {
    text-decoration: none;
  }
  a span {
    font-weight: bold;
    font-family: Catamaran, sans-serif;
    color: var(--pink);
  }
`

const Text = styled.div`
  font-family: Catamaran, sans-serif;
  font-size: 14px;
  color: #000;
  padding-left: 1rem;
  padding-right: 1rem;
  a {
    white-space: nowrap;
  }
  @media (max-width: 1090px) {
    br {
      display: none;
    }
  }
  @media (max-width: 880px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    max-width: calc(100vw - 1rem);
  }
  @media (max-width: 400px) {
    padding-bottom: 2rem;
  }
`

const Companies = styled.div`
  height: 50px;
  display: flex;
  column-gap: 17px;
  min-width: 20%;
  @media(max-width: 880px) {
    padding-top: 1rem;
  }
  img {
    width: 49px;
    height: 49px;
  }
`

const Socials = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  min-width: 20%;
  column-gap: 18px;
  @media(max-width: 880px) {
    padding-top: 2rem;
  }
  img.facebook {
    width: 29px;
    height: 29px;
  }
  img.instagram {
    width: 30px;
    height: 30px;
  }
  img.twitter {
    width: 26px;
    height: 21px;
  }
  img.youtube {
    width: 33px;
    height: 23px;
  }
`

const Footer = () => {

  const year = new Date().getFullYear();

  const [tw, setTw] = useState(Twitter);
  const [fb, setFb] = useState(Facebook);
  const [ins, setIns] = useState(Instagram);
  const [yo, setYo] = useState(Youtube);

  return (
    <div className="no-print">
      <Tooltip id="footer-tooltip" style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', opacity: '0.99', border: '1px solid #fff', padding: '1.5rem', maxWidth: '300px', backgroundColor: '#EE2880', fontFamily: 'Catamaran, sans-serif' }} />
      <Container>
        <Text>
          <Link className="pbj" to="/"><span>PBJ</span></Link>. Exclusive talent agency. | Copyright &copy; 2015-{year} PBJ Management
          | <a href="/privacy-policy.pdf">Privacy Policy</a> | <Link to="/cookie-policy">Cookie Policy</Link><br /> All images and text on this site are strictly under copyright. No unauthorsed reproduction. <Link to="/terms">View terms</Link>.
        </Text>
        <Companies>
          <Link to="/"><img src={LogoPBJ} alt="PBJ" /></Link>
          <a href="http://cobj.co.uk/" data-tooltip-id="footer-tooltip" data-tooltip-content="COBJ is a sister company of PBJ Management and handles all corporate enquiries for PBJ and KBJ clients." data-tooltip-place="top"><img src={LogoCobj} alt="COBJ" /></a>
          <a href="https://www.vobjmanagement.co.uk/" data-tooltip-id="footer-tooltip" data-tooltip-content="VOBJ is the voice-over arm of PBJ Management and handles all voice-over enquiries for PBJ and KBJ clients." data-tooltip-place="top"><img src={LogoVOBJ} alt="VOBJ" /></a>
        </Companies>
        <Socials>
          <a href="https://www.instagram.com/pbjmanagement"><img onMouseLeave={(e) => setIns(Instagram)} onMouseEnter={(e) => setIns(InstagramPink)} className="instagram" src={ins} alt="Instagram" /></a>
          <a href="https://twitter.com/pbjmanagement"><img onMouseLeave={(e) => setTw(Twitter)} onMouseEnter={(e) => setTw(TwitterPink)} className="twitter" src={tw} alt="Twitter" /></a>
          <a href="https://www.youtube.com/pbjmanagement"><img onMouseLeave={(e) => setYo(Youtube)} onMouseEnter={(e) => setYo(YoutubePink)} className="youtube" src={yo} alt="Youtube" /></a>
        </Socials>
      </Container>
    </div>
  )
}

export default Footer
