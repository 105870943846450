import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql, Link, navigate } from 'gatsby'
import { slugify, artistname } from '../../lib/utils'
import Maxwidth from '../maxwidth'
import Logo from '../../images/logo.svg'
import MagnifyingGlass from '../../images/magnifying_glass.svg'
import PopoverMenu from '../popover_menu'
import Facebook from '../../images/facebook.svg'
import Twitter from '../../images/twitter.svg'
import Instagram from '../../images/instagram.svg'
import Youtube from '../../images/youtube.svg'
import Burger from '../../images/burger.svg'
import X from '../../images/x.svg'

const Container = styled.nav`
  max-width: calc(1400px - 2rem);
  margin-left: auto;
  margin-right: auto;
  min-height: 106px;
  display: flex;
  border-bottom: 1px solid #F4B8D3;
  @media(max-width: 1036px) {
    max-width: 100%;
  }
  @media (max-width: 880px) {
    display: none;
  }
`

const Left = styled.div`
  display: flex;
  align-items: center;
  padding-left: 45px;
  span {
   color: var(--darkgrey);
   font: italic normal normal 17px/21px "Playfair Display";
  }
  img {
    width: 82px;
    margin-right: 37px;
  }
  @media(max-width: 1096px) {
    padding-left: 0.5rem;
    img {
      width: 60px;
      margin-right: 1rem;
    }
  }
`

const Right = styled.div`
  display: flex;
  flex: 1 0;
  align-items: center;
  justify-content: flex-end;
  padding-right: 45px;
  img {
    transform: scaleX(-1) translateY(5px);
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  img.search {
    cursor: pointer;
  }
  ul {
    list-style: none;
    padding-left: 0;
  }
  li {
    display: inline-block;
    margin-left: 24px;
    &:first-of-type {
      margin-left: 0;
    }
    a {
      text-decoration: none;
      font-size: 16px;
      color: var(--darkgrey);
      font-family: 'Catamaran', sans-serif;
      transition: all 0.25s ease-in-out;
      &:hover {
        color: var(--pink);
      }
      &.active {
        color: var(--pink);
        font-weight: bold;
      }
    }
  }
  @media(max-width: 1036px) {
    padding-right: 0.5rem;
    li {
      margin-left: 0.75rem;
    }
  }
`

const ContainerMobile = styled.div`
  display: none;
  @media (max-width: 880px) {
    display: flex;
    padding-top: 0.5rem;
    flex-direction: column;
    width: 100%;
  }
`

const Menu = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 0.5rem;
  justify-content: space-between;
  span {
    font-size: 12px;
    color: var(--text);
    font-style: italic;
    opacity: 0.85;
  }
`

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  img {
    height: 50px;
    margin-right: 1rem;
    margin-left: 1rem;
  }
`

const BurgerContainer = styled.div`
  margin-right: 1rem;
`

const Submenu = styled.div`
  background-color: var(--pink);
  color: #fff;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: ${props => props.search ? '9px' : '0.8rem'};
  padding-bottom: ${props => props.search ? '8px' : '0.8rem'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    filter: invert(100%);
  }
`

const SearchInputMobile = styled.div`
  border: none;
  position: relative;
  span {
    font-family: Catamaran, sans-serif;
    position: absolute;
    left: 0;
    top: 2px;
    left: 8px;
    transition: all 0.25s ease-in-out;
    &.visible {
      opacity: 1;
    }
      &.hidden {
        opacity: 0;
      }
  }
  input {
    background-color: var(--pink);
    outline: 0;
    border: none;
    font-size: 16px;
    margin: 0;
    color: #fff;
    font-family: Catamaran, sans-serif;
    ::placeholder,
    ::-webkit-input-placeholder {
      color: #fff;
      opacity: 1;
    }
    :-ms-input-placeholder {
      color: #fff;
      opacity: 1;
    }
  }

`

const Search = styled.div`
  position: relative;
  img.close {
    filter: invert(0%);
    width: 10px;
    height: 10px;
  }
  img {
    cursor: pointer;
    transform: scaleX(-1);
    &.close {
      width: 26px;
      height: 26px;
    }
  }
`

const Logos = styled.div`
  display: flex;
column-gap: 0.8rem;
  padding-top: 2px;
padding-bottom: 1px;
  img {
    height: 18px;
  }
`

const Searchbox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  transform: translateY(-5px) translateX(7px);
  input {
    &:focus {
      outline-width: 0;
      outline: none;
    }
    border-left: none;
    border-right: none;
    text-align: right;
    border-top: none;
    border-radius: 0;
    border-bottom: 1px solid #eee;
    font-family: Catamaran, sans-serif;
    transform: translateY(0.25rem);
  }
  img {
    filter: invert(100%);
    opacity: 0.7;
    width: 2rem;
    height: 2rem;
    margin-left: 1rem;
    cursor: pointer;
  }
`

const Voiceover = styled.a`
  display: block;
  color: #FF7700;
  border: 1px solid #FF7700;
  font-family: Catamaran, sans-serif;
  padding: 6px 12px;
  margin-left: 20px;
  font-weight: 300;
  text-decoration: none;
  &:hover {
    color: #FF7700;
  }
`

const ResultsPopup = styled.ul`
  display: block;
  position: absolute;
  z-index: 9999999999;
  width: 280px;
  border-width: 1px;
  border-style: solid;
  background-color: #fff;
  transition: all 0.2s linear;
  right: 110px;
  top: 80px;
  padding: 0.7rem;
  margin: 0;
  &.visible {
    opacity: 1;
    transform: translateY(0px);
    li {
      display: inline-block;
    }
  }
  &.hidden {
    background-color: transparent;
    border-color: transparent;
    pointer-events: none;
    transform: translateY(10px);
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition-duration: 5ms;
    li {
      display: none;
    }
  }
  @media (max-width: 880px) {
    rigth: 0px;
    top: 112px;
    left: 0px;
    border: none;
    padding: 0;
    width: 100%;
    max-height: calc(100vh - 110px);
    overflow-y: auto;
  }
  li {
    list-style: none;
    font-family: Catemeran, sans-serif;
    padding: 0.5rem 0.7rem 0.3rem 0.7rem;
    cursor: pointer;
    transition: all 0.25s ease-in-out;
    color: #817f80;
    font-weight: 300;
    @media (max-width: 880px) {
      padding: 1rem 1rem 0.6rem 1rem;
    }
    &:hover {
      color: var(--pink);
    }
    span {
      display: block;
      font-family: Catemeran, sans-serif;
      font-size: 11px;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-weight: bold;
      color: #000;
      &.agent {
        color: var(--pink);
      }
    }
    &:nth-of-type(odd) {
      background-color: #f8f8f8;
    }
  }
`

const Nav = () => {

  const [menuVisible, setMenuVisible] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);
  const [searchVisibleMobile, setSearchVisibleMobile] = useState(false);
  const [searchterm, setSearchterm] = useState('');
  const [searchresults, setSearchresults] = useState(false);
  const [searchresultsartists, setSearchresultsartists] = useState(false);
  const [searchresultsagents, setSearchresultsagents] = useState(false);

  const isBrowser = typeof window !== "undefined"

  const data = useStaticQuery(graphql`
  query {
     allSitePage {
      edges {
        node {
          path
        }
      }
    }
     allSanityMenu {
       edges {
         node {
           links {
             title
             url
            }
          }
        }
      }
      allSanityArtist(sort: {fields: first_name}) {
        edges {
          node {
            surname
            first_name
          }
        }
      }
      allSanityPerson(sort: {fields: title}) {
        edges {
          node {
            slug {
              current
            }
            pbj_staff
            title
            email
          }
        }
      }
    }
  `)

  const menu_links = data.allSanityMenu.edges[0].node.links;

  const all_page_links = data.allSitePage.edges.map(p => p.node.path);

  const all_artists = data.allSanityArtist.edges.map(a => {
    return artistname(a.node)
  })

  const all_agents = data.allSanityPerson.edges.map(a => {
    if (a.node.pbj_staff === true) {
      return a.node.title
    } else {
      return false;
    }
  }).filter(Boolean)

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        closeSearch()
      }
    };
    if (isBrowser) {
      window.addEventListener('keydown', handleEsc);
    }

    return () => {
      if (isBrowser) {
        window.removeEventListener('keydown', handleEsc);
      }
    };
  }, []);

  useEffect(() => {

    if (searchterm && searchterm.length > 1) {

      const results_artists = all_artists.filter(a => a.toLowerCase().includes(searchterm.toLowerCase()));
      const results_agents = all_agents.filter(a => a.toLowerCase().includes(searchterm.toLowerCase()));
      const results = [...results_artists, ...results_agents];

      if (searchterm.length > 0 && results_artists.length > 0) {
        setSearchresultsartists(results_artists);
      } else {
        setSearchresultsartists(false);
      }

      if (searchterm.length > 0 && results_agents.length > 0) {
        setSearchresultsagents(results_agents);
      } else {
        setSearchresultsagents(false);
      }

      if (searchterm.length > 0 && results.length > 0) {
        setSearchresults(results);
      } else {
        setSearchresults(false);
      }

    }

  }, [searchterm]);

  const closeSearch = () => {
    setSearchterm('');
    setSearchresults(false);
    setSearchresultsartists(false);
    setSearchresultsagents(false);
    setSearchVisible(false);
    setSearchVisibleMobile(false);
  }

  const goto = (url) => {
    setSearchterm('');
    setSearchresults(false);
    setSearchresultsartists(false);
    setSearchresultsagents(false);
    setSearchVisible(false);
    setSearchVisibleMobile(false);
    navigate(url);
  }

  return (
    <div className="no-print">
      <PopoverMenu all_page_links={all_page_links} menuVisible={menuVisible} setMenuVisible={setMenuVisible} />

      <ResultsPopup className={searchresults && searchresults.length > 0 && (searchVisible || searchVisibleMobile) ? 'vislble' : 'hidden'}>
        {searchresultsartists && searchresultsartists.map((a, index) => {
          const url = '/artists/' + slugify(a);
          return (
            <li key={index} onClick={() => goto(url)}>
              <span>Artist</span> {a}
            </li>
          )
        })}
        {searchresultsagents && searchresultsagents.map((a, index) => {
          return (
            <li key={index} onClick={() => goto('/people/' + slugify(a))}>
              <span className="agent">PBJ Agent</span> {a}
            </li>
          )
        })}
      </ResultsPopup>

      <ContainerMobile>
        <Menu>
          <LogoContainer>
            <Link activeClassName="active" to="/"><img src={Logo} alt="PBJ" /></Link><span>Exclusive talent agency</span>
          </LogoContainer>
          <BurgerContainer>
            <img src={Burger} alt="" onClick={() => setMenuVisible(true)} />
          </BurgerContainer>
        </Menu>
        <Submenu search={searchVisibleMobile}>
          {!searchVisibleMobile &&
            <Logos>
              <a href="https://www.instagram.com/pbjmanagement"><img className="instagram" src={Instagram} alt="Instagram" /></a>
              <a href="https://twitter.com/pbjmanagement"><img className="twitter" src={Twitter} alt="Twitter" /></a>
              <a href="https://www.youtube.com/pbjmanagement"><img className="youtube" src={Youtube} alt="Youtube" /></a>
            </Logos>
          }
          {searchVisibleMobile &&
            <SearchInputMobile>
              <input autoFocus type="text" name="mobilesearch" value={searchterm} onChange={(e) => setSearchterm(e.target.value)} />
              <span className={searchterm ? 'hidden' : 'visible'}>Search...</span>
            </SearchInputMobile>
          }
          <Search>
            {!searchVisibleMobile &&
              <img src={MagnifyingGlass} alt="Search" onClick={() => setSearchVisibleMobile(true)} />
            }
            {searchVisibleMobile &&
              <img src={X} alt="Close" className="close" onClick={() => closeSearch()} />
            }
          </Search>
        </Submenu>
      </ContainerMobile>
      <Maxwidth nominheight>
        <Container>
          <Left><Link activeClassName="active" to="/"><img src={Logo} alt="PBJ" /></Link><span>Exclusive talent agency</span></Left>
          <Right>
            {searchVisible &&
              <Searchbox>
                <input autoFocus name="search" type="text" onChange={(e) => setSearchterm(e.target.value)} value={searchterm} />
                <img src={X} alt="Close" onClick={() => closeSearch()} />
              </Searchbox>
            }
            {!searchVisible &&
              <ul>
                {
                  menu_links.map((m, index) => {
                    const cn = m.url.replace('/', '');
                    if (all_page_links.includes(m.url) || all_page_links.includes(m.url + '/')) {
                      return (
                        <li key={index}><Link className={cn} activeClassName="active" to={m.url}>{m.title}</Link></li>
                      )
                    }
                  })
                }
                <li><img className="search" onClick={() => setSearchVisible(true)} src={MagnifyingGlass} alt="Search" /></li>
              </ul>
            }
            <div><Voiceover href="https://www.vobjmanagement.co.uk/">Voiceover</Voiceover></div>
          </Right>
        </Container>
      </Maxwidth>
    </div>
  )
}

export default Nav;
