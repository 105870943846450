import React from 'react'
import styled from 'styled-components'

const Container = styled.main`
  max-width: calc(1400px - 2rem);
  margin-left: auto;
  margin-right: auto;
  min-height: ${props => props.nominheight ? '0' : "calc(100vh - 236px)"};
  padding-left: 1rem;
  padding-right: 1rem;
  @media (max-width: 880px) {
    max-width: calc(100%);
    padding-left: 0;
    padding-right: 0;
  }
`

const Maxwidth = ({ children, nominheight, path }) => {

  const fullbleed_paths = ['contact','artist']
  const first_path = path ? path.split('/')[1] : false;

  if (path !== undefined && fullbleed_paths.includes(first_path)) {
    return (
      <div>{children}</div>
    )
  } else {
    return (
      <Container nominheight={nominheight}>{children}</Container>
    )
  }
}

export default Maxwidth;
