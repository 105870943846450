import React from "react";
import { motion } from "framer-motion";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import CookieConsent, { Cookies } from "react-cookie-consent";
import GlobalStyle from "../components/globalstyle";
import Maxwidth from "../components/maxwidth";
import Nav from "../components/nav";
import Footer from "../components/footer";

export default function Layout(props) {
  const { children, path } = props;

  const page_type = props.pageContext.pagetype;

  if (path === "/") {
    return (
      <React.Fragment>
        <GlobalStyle />
        <motion.div
          key={path}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          {children}
        </motion.div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <GlobalStyle />
        <Nav />
        <motion.div
          key={path}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          {page_type !== "page" && <Maxwidth path={path}>{children}</Maxwidth>}
          {page_type === "page" && <>{children}</>}
        </motion.div>
        <Footer />
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          declineButtonText="Decline"
          enableDeclineButton
          onDecline={() => {
            console.log("declined");
          }}
          cookieName="gatsby-gdpr-google-tagmanager"
          style={{ background: "#F81278" }}
          expires={150}
        >
          This website uses cookies to enhance the user experience.{" "}
        </CookieConsent>
      </React.Fragment>
    );
  }
}
