export function artistname(artist) {
    let artist_name = artist.first_name;
    if (artist.surname) {
        artist_name = artist_name + ' ' + artist.surname;
    }
    return artist_name.trim();
}

export function slugify(str) {
    str = str.replace(/^\s+|\s+$/g, '');

    // Make the string lowercase
    str = str.toLowerCase();

    var stars = '';

    if (str.slice(0, 5) === '*****') {
        stars = 'five stars'
    } else if (str.slice(0, 4) === '****') {
        stars = 'four stars'
    } else if (str.slice(0, 3) === '***') {
        stars = 'three stars'
    } else if (str.slice(0, 2) === '**') {
        stars = 'two stars'
    } else if (str.slice(0, 1) === '*') {
        stars = 'one star'
    }

    str = stars + str;

    // Remove accents, swap ñ for n, etc
    var from = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;'";
    var to = "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa-------";
    for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    // Remove invalid chars
    str = str.replace(/[^a-z0-9 -]/g, '')
        // Collapse whitespace and replace by -
        .replace(/\s+/g, '-')
        // Collapse dashes
        .replace(/-+/g, '-');

    return str;
}

export function formatDate(date) {
    return (date);
}
